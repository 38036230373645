import React from 'react';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import { Link, navigate } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SEO } from '../components/SEO';
import Layout from '../components/layout';
import { compareSlashInsensitive, getNestedValue } from '../utils/utils';
import { excelOnlineCourseFrontPageUrl } from '../utils/config';

let parentGlobal = false;

export default ({ data, location }) => {
  const post = data.markdownRemark;
  // get parent key, such as Kurssin esittely or Käyttöliittymä
  const menuMainLevelKey = getMenuMainLevelKey(
    location.pathname,
    data.allMenuHierarchyJson.nodes
  );
  const initialActiveKey =
    menuMainLevelKey + removeTrailingSlash(location.pathname);
  const isOnlineCourseHome = compareSlashInsensitive(
    location.pathname,
    excelOnlineCourseFrontPageUrl
  );
  const mainTitle = isOnlineCourseHome
    ? post.frontmatter.title
    : 'Excel: ' + post.frontmatter.title;

  const description = getNestedValue(
    ['frontmatter', 'meta', 'description'],
    post
  );
  return (
    <>
      <SEO title={mainTitle} description={description} />
      <Layout
        branding="onlinecourse"
        mainTitle={mainTitle}
        mainTitleClasses={'mt-3 mb-3'}
        headerNavLinkStyle={{ color: 'white' }}
        headerBgStyles={{
          backgroundColor: '#165832',
        }}
        tagLine={
          isOnlineCourseHome ? (
            undefined
          ) : (
            <div className="tagLine-online">
              Kurssilta:{' '}
              <Link to="/fi/excel-kurssi-netissa">
                Excel-peruskurssi netissä
              </Link>
            </div>
          )
        }
        location={location}
      >
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="videoWrapper">
                {post.frontmatter.video_url === 'missing: 101' ? (
                  <iframe
                    allowFullScreen
                    className="tscplayer_inline"
                    id="embeddedSmartPlayerInstance"
                    scrolling="no"
                    src="https://www.officekoulutus.fi/videot/intro2/intro2_player.html?embedIFrameId=embeddedSmartPlayerInstance"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <iframe
                    id="videoplayer"
                    src={post.frontmatter.video_url}
                    width="853"
                    height="480"
                    frameBorder="0"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <nav>
                <TreeMenu
                  hasSearch={false}
                  initialActiveKey={initialActiveKey}
                  initialOpenNodes={[menuMainLevelKey]}
                  data={data.allMenuHierarchyJson.nodes}
                >
                  {({ items }) => (
                    <ul className="tree-item-group">
                      {items.map((props, index) => {
                        return (
                          <ItemComponent
                            {...props}
                            closedIcon="+"
                            onClick={e => {
                              if (props.hasNodes) {
                                // Force open/close items if item has child nodes
                                props.onClick();
                                props.hasNodes &&
                                  props.toggleNode &&
                                  props.toggleNode();
                                e.stopPropagation();
                              } else {
                                navigate(props.url);
                              }
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}
                </TreeMenu>
              </nav>
            </div>
            <div className="col-md-8">
              <Tabs>
                <TabList>
                  <Tab>Videon puhe tekstinä</Tab>
                  <Tab>Kurssin kuvaus</Tab>
                  <Tab>Harjoitustiedostot</Tab>
                </TabList>

                <TabPanel>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </TabPanel>
                <TabPanel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.courseDescriptionSnippet.html,
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.exerciseSnippet.html,
                    }}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        video_url
        meta {
          description
        }
      }
    }
    allMenuHierarchyJson {
      nodes {
        label
        key
        nodes {
          key
          label
          url
        }
      }
    }
    courseDescriptionSnippet: markdownRemark(
      frontmatter: {
        type: { eq: "snippet" }
        id: { eq: "online-course-description" }
      }
    ) {
      html
    }
    exerciseSnippet: markdownRemark(
      frontmatter: { type: { eq: "snippet" }, id: { eq: "exercise-files" } }
    ) {
      html
    }
  }
`;

function removeTrailingSlash(str) {
  // Remove trailing slash if present
  if (str.slice(-1) === '/') {
    str = str.slice(0, -1);
  }
  return str;
}

function getMenuMainLevelKey(pathname, menuHierarchy) {
  // const pattern = /\/(?<keyw>[^/]+)$/;
  // const matchObject = pathname.match(pattern);
  // let currentMenuItemKey = 'moi';
  // if (matchObject && matchObject.groups.key) {
  //   currentMenuItemKey = matchObject.groups.key;
  // }

  // Remove slash in beginning of the string and trailing slash if it exists
  const currentMenuItemKey = removeTrailingSlash(pathname.substr(1));

  // Recursively search through menu hierarchy and get the parent
  let parent = recursiveFindParent(
    menuHierarchy,
    currentMenuItemKey,
    'no-parent'
  );
  return parent;
}

function recursiveFindParent(menuHierarchy, keyToBeFound, parentKey) {
  if (
    menuHierarchy.some(element => {
      if (element.key && element.key === keyToBeFound) {
        parentGlobal = parentKey;
        return true;
      } else {
        if (element.nodes) {
          return recursiveFindParent(element.nodes, keyToBeFound, element.key);
        }
      }
    })
  ) {
    return parentGlobal;
  }
  return false;
}
